//
// reboot.scss
//

body {
    padding-right: 0px !important;
    padding-left: 0px !important;
    &.loading {
        visibility: hidden;
        opacity: 0;
    }
}

Forms
label {
    font-weight: $label-font-weight;
    // color: $dark;
}


pre{
  background-color: lighten($gray-200, 2%);
  border: 1px solid $gray-300;
  padding: 7px;
}

button:focus {
    outline: none;
}

 // blockquote

.blockquote{
    padding: 10px 20px;
    margin-bottom: $blockquote-margin-y;
    border-left: 4px solid $gray-300;
    @include font-size($blockquote-footer-font-size);

    &.blockquote-reverse{
      border-left: 0;
      border-right: 4px solid $gray-300;
      text-align: right;
    }
  }
  
.blockquote-footer {
    margin-top: $blockquote-margin-y;
    margin-bottom: $blockquote-margin-y;
    @include font-size($blockquote-footer-font-size);
    color: $blockquote-footer-color;
  
    &::before {
      content: "\2014\00A0"; // em dash, nbsp
    }
  }